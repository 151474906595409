<template>
  <div class="publish-wrap">
    <div style="display: flex; justify-content: space-between">
      <div>
        <p style="display: inline;margin-right:36px">标题：</p>
        <el-input
          v-model="content.title"
          placeholder="请输入文章标题"
          style="width: 600px"
        ></el-input>
      </div>
      <el-button class="style_button" type="text" @click="publishClick"
        >发布</el-button
      >
    </div>

      <div
        style="display: flex; margin: 20px 0"
        
      >
       <div>
          <p style="display: inline;margin-right:20px">发布人：</p>
          <el-input
          v-model="content.username"
          placeholder="请输入发布人"
          style="width: 600px"
        ></el-input>
        </div>
      </div>
       <div
        style="display: flex; margin: 20px 0"
        
      >
        <div>
          发布时间：
          <el-date-picker
            v-model="content.date"
            type="date"
            style="width: 600px"
            placeholder="选择发布日期">
          </el-date-picker>
        </div>
      </div>
    <div class="preview" v-if="type == 'news'" style="margin:20px 0">
      <p style="display: inline;margin-right:20px">封面图：</p>
      <el-upload
        class="avatar-uploader"
        :action="action"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeUpload"
      >
        <img
          v-if="img1"
          :src="img1"
          class="avatar"
        />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </div>

    <tinymce-editor
          ref="editor"
          v-model="content.text"
          :init="option"
        />

    <!-- <div>
      <quill-editor
        class="editor"
        ref="myTextEditor"
        v-model="content.text"
        :options="editorOption"
        @change="onEditorChange($event)"
      ></quill-editor>
      <form action method="post" enctype="multipart/form-data" id="uploadFormMulti">
      <input
        style="display: none"
        :id="uniqueId"
        type="file"
        name="file"
        multiple
        accept="image/jpg, image/jpeg, image/png, image/gif"
        @change="uploadImg('uploadFormMulti')"
      />
    </form>
    </div> -->
  </div>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor/index.vue';
import { BASE_URL } from "@/config";
import {
  getInfoById,
  updateInfo,
  addInfo,
  uploadImages
} from "@/api/message";
import { Message } from "element-ui";

export default {
  name: "xxx",
  components: { TinymceEditor },
  mounted() {
    this.action = BASE_URL + `/system/fileUpload`;
    
    this.type = this.$route.query.type;
    console.log(this.type)
    this.content.proid = JSON.parse(window.localStorage.getItem("tltz_userinfo")).procuratorate.id;
    if( this.type == "news"){
      this.content.type= 1;
    }else if( this.type == "notice"){
      this.content.type= 0;
    }else if( this.type == "problem"){
      this.content.type= 2;
    }
    this.id = this.$route.query.id;
    if (this.id !== undefined) {
        this._getInfoById(this.id);
      }
  },
  data() {
    return {
      option: {
        height: 520,
        // 自定义文件上传(这里使用把选择的文件转成 blob 演示)
        file_picker_callback: (callback, _value, meta) => {
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          // 设定文件可选类型
          if (meta.filetype === 'image') {
            input.setAttribute('accept', 'image/*');
          } else if (meta.filetype === 'media') {
            input.setAttribute('accept', 'video/*');
          }
          input.onchange = () => {
            const file = input.files?.[0];
            if (!file) {
              return;
            }
            if (meta.filetype === 'media') {
              if (!file.type.startsWith('video/')) {
                this.$refs.editor.alert({ content: '只能选择视频文件' });
                return;
              }
            }
            if (file.size / 1024 / 1024 > 100) {
              this.$refs.editor.alert({ content: '大小不能超过 100MB' });
              return;
            }
            const formData = new FormData();
              formData.append('file', file, file.name);
              uploadImages(formData).then(res => {
                console.log('页面',res);
                  if (res.code == 200) {
                      callback(res.data);
                  } else {
                    this.$refs.editor.alert({ content: res.msg });
                    // console.log('错误信息',res.msg);
                    // this.$message.error(res.msg);
                  }
              }).catch(e => {
                  console.error(e);
                  this.$message.error(e.msg);
              });
          };
          input.click();
        }
      },
      content: {
        img:'',
        username:'',
        title:'',
        proid:null,
        text:'',
        date:'',
        type:null,
      },
      img1:'',
      uniqueId: "uniqueId",
      action: "",
      quillUpdateImg: false, // 根据图片上传状态来确定是否显示loading动画，刚开始是false,不显示
      editorOption: {
        modules: {
          toolbar,
        },
      },
      type: '', 
      id: "",
    };
  },

  methods: {
    _getInfoById(id) {
      //获取新闻信息
      getInfoById(id).then((data) => {
        this.content = data.data;
        this.img1 = data.data.img
      });
    },
    onEditorChange(editor) {
      //富文本
      this.content.text = editor.html;
      this.$emit("content", editor.html);
    },
    uploadImg: async function() {
      var _this = this;
      //构造formData对象
      var formData = new FormData();
      formData.append("file", document.getElementById(_this.uniqueId).files[0]);

      
        //调用上传文件接口
        uploadImages(formData).then(res => {
          //返回上传文件的地址
          let url = res.data;
          console.log(res)
          if (url != null && url.length > 0) {
            let Range = _this.editor.getSelection();
            // url = url.indexOf("http") != -1 ? url : "http:" + url;
            //上传文件成功之后在富文本中回显(显示)
            _this.editor.insertEmbed(
              Range != null ? Range.index : 0,
              "image",
              url
            );
          } else {
            _this.$message.error("图片上传失败");
          }
          //成功之后,将文件的文本框的value置空
          document.getElementById(_this.uniqueId).value = "";
        });
      
    },
    //预览图
    handleAvatarSuccess(res, file) {
      this.img1 = URL.createObjectURL(file.raw);
      console.log(this.content.img)
      this.content.img = file.response.data;
    },

    beforeUpload(file) {       
      console.log(file)
      var testmsg=file.name.substring(file.name.lastIndexOf('.')+1)                        
      const extension = testmsg === 'png'            
      const extension2 = testmsg === 'jpg'           
      const extension3 = testmsg === 'jpeg'           
      const isLt2M = file.size / 1024 / 1024 < 5          
      if(!extension && !extension2 && !extension3) {                
        Message.info('上传文件只能是 jpg/jpeg/png格式!');
        return  extension || extension2           
      }            
      if(!isLt2M) {           
        Message.info('上传文件大小不能超过 5MB!');           
    }            
    return extension || extension2 && isLt2M            
        // return extension || extension2        
    },
    publishClick() {
      //发布
      console.log(this.content);
      console.log(this.type);
       if(this.content.username === ''){
          Message.error("撰稿人员不能为空！");
          return
        }
        if(this.content.title === ''){
          Message.error("标题不能为空！");
          return
        }
        if(this.content.text === ''){
          Message.error("内容不能为空！");
          return
        }
        if(this.content.date === ''){
          Message.error("请选择发布日期！");
          return
        }
          this.content.date = new Date(this.content.date).toLocaleDateString().replace(/\//g, '-')

      if (this.type == "problem") {
        if (this.id !== undefined) {
            updateInfo(this.content).then((data) => {
              console.log(data)
            if (data.code == 200) {
              this.$router.push(`./problem`);
              Message.success("修改成功！");
            }else{
              Message.error(data.msg);
            }
          });
        } else {
          addInfo(this.content).then((data) => {
            if (data.code == 200) {
              this.$router.push(`./problem`);
              Message.success("发布成功！");
              return;
            }else{
              Message.error(data.msg);
            }
          });
        }
      } else if (this.type == "notice") {
        if (this.id !== undefined) {
          updateInfo(this.content).then((data) => {
            if (data.code == 200) {
              this.$router.push(`./notices`);
              Message.success("修改成功！");
              return;
            }else{
              Message.error(data.msg);
            }
          });
        } else {
          console.log(this.content)
          addInfo(this.content).then((data) => {
            if (data.code == 200) {
              this.$router.push(`./notices`);
              Message.success("发布成功！");
              return;
            }else{
              Message.error(data.msg);
            }
          });
        }
      }else if (this.type == "news") {
        if(this.content.img === ''){
          Message.error("封面图不能为空！");
          return
        }
        if (this.id !== undefined) {
          updateInfo(this.content).then((data) => {
            if (data.code == 200) {
              this.$router.push(`./news`);
              Message.success("修改成功！");
              return;
            }else{
              Message.error(data.msg);
            }
          });
        } else {
          addInfo(this.content).then((data) => {
            if (data.code == 200) {
              this.$router.push(`./news`);
              Message.success("发布成功！");
              return;
            }else{
              Message.error(data.msg);
            }
          });
        }
      }
    },
  },
};
</script>
<style lang='less' scoped>
.publish-wrap {
  box-sizing: border-box;
  position: relative;
  width: auto;
  height: 92%;
  background: #fff;
  margin-top: 16px;
  padding: 20px 13px 0 13px;
}
.editor {
  line-height: normal !important;
  height: 380px;
  margin-top: 20px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
.preview {
  display: flex;
  .avatar-uploader {
    border: 1px dashed #8c939d;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 240px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 240px;
    height: 140px;
    display: block;
  }
}
.style_button {
  width: 100px;
  height: 40px;
  background: #044bfe;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
  margin-right: 100px;
}
</style>
